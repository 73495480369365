// extracted by mini-css-extract-plugin
export var grid = "_6-0";
export var span1 = "_6-2";
export var span10 = "_6-b";
export var span10L = "_6-z";
export var span10M = "_6-n";
export var span10Xl = "_6-1b";
export var span11 = "_6-c";
export var span11L = "_6-10";
export var span11M = "_6-o";
export var span11Xl = "_6-1c";
export var span12 = "_6-d";
export var span12L = "_6-11";
export var span12M = "_6-p";
export var span12Xl = "_6-1d";
export var span1L = "_6-q";
export var span1M = "_6-e";
export var span1Xl = "_6-12";
export var span2 = "_6-3";
export var span2L = "_6-r";
export var span2M = "_6-f";
export var span2Xl = "_6-13";
export var span3 = "_6-4";
export var span3L = "_6-s";
export var span3M = "_6-g";
export var span3Xl = "_6-14";
export var span4 = "_6-5";
export var span4L = "_6-t";
export var span4M = "_6-h";
export var span4Xl = "_6-15";
export var span5 = "_6-6";
export var span5L = "_6-u";
export var span5M = "_6-i";
export var span5Xl = "_6-16";
export var span6 = "_6-7";
export var span6L = "_6-v";
export var span6M = "_6-j";
export var span6Xl = "_6-17";
export var span7 = "_6-8";
export var span7L = "_6-w";
export var span7M = "_6-k";
export var span7Xl = "_6-18";
export var span8 = "_6-9";
export var span8L = "_6-x";
export var span8M = "_6-l";
export var span8Xl = "_6-19";
export var span9 = "_6-a";
export var span9L = "_6-y";
export var span9M = "_6-m";
export var span9Xl = "_6-1a";
export var stretch = "_6-1";