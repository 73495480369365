// extracted by mini-css-extract-plugin
export var active = "_q-3";
export var activeWrapper = "_q-0";
export var button = "_q-1";
export var collapsed = "_q-i";
export var divider = "_q-7";
export var dividerSection = "_q-6";
export var dropdown = "_q-8";
export var dropdownChevron = "_q-a";
export var expand = "_q-9";
export var fullscreen = "_q-r";
export var icon = "_q-n";
export var label = "_q-5 _5-0";
export var labelWithBubble = "_q-p _5-0";
export var logo = "_q-j";
export var logoSection = "_q-m";
export var main = "_q-q";
export var menu = "_q-b";
export var menuHeader = "_q-c _5-0";
export var menuItem = "_q-d _5-0";
export var nav = "_q-e";
export var noBackground = "_q-2";
export var option = "_q-4";
export var options = "_q-g";
export var optionsContainer = "_q-f";
export var page = "_q-h";
export var rotate = "_q-o";
export var section = "_q-k";
export var settings = "_q-t";
export var subitem = "_q-s";
export var subitemActive = "_q-x";
export var subitemLabel = "_q-w";
export var subitemLine = "_q-v";
export var subitemLineContainer = "_q-u";
export var subsection = "_q-l";