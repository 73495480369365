// extracted by mini-css-extract-plugin
export var box = "_14-0";
export var check = "_14-1";
export var checkboxButton = "_14-6";
export var checkboxGroup = "_14-5";
export var disabled = "_14-7";
export var ghost = "_14-9";
export var input = "_14-2";
export var label = "_14-4";
export var normal = "_14-8";
export var uncheck = "_14-3";